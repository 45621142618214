import React from "react";
import { Logo } from "../../../../assets";
import { Link } from "react-router-dom";
import { HiXMark } from "react-icons/hi2";
import "./nav.css";

const Nav = () => {
  return (
    <nav className="adNav">
      <img src={Logo} alt="logo" className="adLogo" />
      <div className="nav-btn">
        <Link to="">Private Sale</Link>
        <Link to="/">
          <HiXMark size={32} color="#474EFF" />
        </Link>
      </div>
    </nav>
  );
};

export default Nav;