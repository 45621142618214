import React from "react";
import { Nav, Form, Footer } from "./components";

const Airdrop = () => {
  return (
    <div className="airdrop">
      <Nav />
      <Form />
      <Footer />
    </div>
  );
};

export default Airdrop;
