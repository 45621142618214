import React from 'react'
import "../App.css";
import { CoinLarge, CoinSmall } from "../assets";
import { Features, Footer, Home, Join, Nav } from "../components";

const Landing = ({ openModal }) => {
  return (
    <div className="App">
      <img src={CoinLarge} alt="coin-large" className="coin-large" />
      <img src={CoinSmall} alt="coin-small" className="coin-small" />
      <Nav className="sticky-nav" openModal={openModal}/>
      <Home />
      <Features />
      <Join />
      <Footer />
    </div>
  );
};

export default Landing