import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Landing from "./pages/Landing";
import Airdrop from "./pages/Airdrop/Airdrop";
import { useState } from "react";
import { ComingSoon } from "./components";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Landing openModal={openModal}/>} />
          <Route path="/airdrop" element={<Airdrop />} />
        </Routes>
      </Router>
      <ComingSoon isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default App;
