import React from "react";
import "./nav.css";
import { Logo } from "../../assets";

const Nav = ({ openModal }) => {
  return (
    <nav className="sticky-nav">
      <img src={Logo} alt="logo" className="logo" />
      <div className="nav-buttons">
        <button>Private Sale</button>
        <button onClick={openModal}>List Airdrop</button>
      </div>
    </nav>
  );
};

export default Nav;
