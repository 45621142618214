import React, { useState, useRef } from "react";
import "./form.css";

const FileUploadButton = ({ onFileChange }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "image/jpeg" && file.size <= 5 * 1024 * 1024) {
      setSelectedFile(file);
      setFileUrl(URL.createObjectURL(file));
      if (onFileChange) {
        onFileChange(file);
      }
    } else {
      alert("Please select a JPG file smaller than 5MB.");
      setSelectedFile(null);
      setFileUrl(null);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept=".jpg, .jpeg"
        onChange={handleFileChange}
      />
      {selectedFile ? (
        <>
          <button
            type="button"
            className="custom-file-upload"
            onClick={() => window.open(fileUrl, "_blank")}
          >
            View
          </button>
          <img
            src={fileUrl}
            alt="Selected"
            style={{ display: "none" }}
            id="uploadedImage"
          />
        </>
      ) : (
        <button
          type="button"
          className="custom-file-upload"
          onClick={triggerFileInput}
        >
          Upload
        </button>
      )}
    </div>
  );
};

export default FileUploadButton;
