import React from "react";
import "./join.css";

const Join = () => {
  return (
    <section className="join-container">
      <div className="join">
        <h1>Join UniTap</h1>
        <p>
          Ready to tap into the future of earning rewards on the blockchain?
        </p>
        <button>LAUNCH NOW</button>
      </div>
    </section>
  );
};

export default Join;
