import React from "react";
import "./footer.css";
import {
  Discord,
  Facebook,
  Instagram,
  LinkedIn,
  LogoBackup,
  Telegram,
  Twitter,
  WhatsApp,
  Youtube,
} from "../../../../assets";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <img src={LogoBackup} alt="logo" className="footer-logo" />
        <div className="footer-top__socials">
          <p>Join UniTap Community</p>
          <div className="footer-top__socials-icons">
            <div className="img-box">
              <img src={Telegram} alt="social" className="telegram" />
            </div>
            <div className="img-box">
              <img src={Twitter} alt="social" className="twitter" />
            </div>
            <div className="img-box">
              <img src={Instagram} alt="social" className="twitter" />
            </div>
            <div className="img-box">
              <img src={Facebook} alt="social" className="twitter" />
            </div>
            <div className="img-box">
              <img src={Discord} alt="social" className="twitter" />
            </div>
            <div className="img-box">
              <img src={Youtube} alt="social" className="twitter" />
            </div>
            <div className="img-box">
              <img src={WhatsApp} alt="social" className="twitter" />
            </div>
            <div className="img-box">
              <img src={LinkedIn} alt="social" className="twitter" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; Copyright Universal Tap Inc, 2024, All Right Reserved.
      </div>
    </footer>
  );
};

export default Footer;
