import React from "react";
import "./home.css";
import { HeroImg} from "../../assets";

const Home = () => {
  return (
    <header>
      <div className="home">
        <div className="home-contents">
          <h1>Discover the Future of Earning with UniTap</h1>
          <p>Saving time & resources for Users and Builders</p>
          <button>TAP TAP HERE</button>
        </div>
        <img src={HeroImg} alt="hero-img" className="hero-img" />
      </div>
    </header>
  );
};

export default Home;
