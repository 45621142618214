import React from "react";
import "./footer.css";
import {
  Discord,
  Facebook,
  Instagram,
  LinkedIn,
  LogoBackup,
  Telegram,
  Twitter,
  WhatsApp,
  Youtube,
} from "../../assets";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <img src={LogoBackup} alt="logo" className="footer-logo" />
        <div className="footer-top__socials">
          <p>Join UniTap Community</p>
          <div className="footer-top__socials-icons">
            <Link
              className="img-box"
              target="_blank"
              to={"https://t.me/universal_tap"}
            >
              <img src={Telegram} alt="social" className="telegram" />
            </Link>
            <Link
              className="img-box"
              target="_blank"
              to={"https://x.com/unitapbot?s=21&t=WlV3qlQAn9wDNEXENJxDSg"}
            >
              <img src={Twitter} alt="social" className="twitter" />
            </Link>
            <Link
              className="img-box"
              target="_blank"
              to={"https://www.instagram.com/unitap.bot?igsh=Y2NnankyOWVjZzd0"}
            >
              <img src={Instagram} alt="social" className="instagram" />
            </Link>
            <Link
              className="img-box"
              target="_blank"
              to={
                "https://www.facebook.com/profile.php?id=61560013679609&mibextid=LQQJ4d"
              }
            >
              <img src={Facebook} alt="social" className="twitter" />
            </Link>
            <Link className="img-box" target="_blank" to={""}>
              <img src={Discord} alt="social" className="twitter" />
            </Link>
            <Link
              className="img-box"
              target="_blank"
              to={"https://www.YouTube.com/@universaltap"}
            >
              <img src={Youtube} alt="social" className="youtube" />
            </Link>
            <Link
              className="img-box"
              target="_blank"
              to={"https://whatsapp.com/channel/0029VafZyObKgsNoCxCMos2g"}
            >
              <img src={WhatsApp} alt="social" className="twitter" />
            </Link>
            <Link
              className="img-box"
              target="_blank"
              to={"https://www.linkedin.com/company/universal-tap"}
            >
              <img src={LinkedIn} alt="social" className="linkedin" />
            </Link>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; Copyright Universal Tap Inc, 2024, All Right Reserved.
      </div>
    </footer>
  );
};

export default Footer;
