import React from "react";
import "./comingSoon.css";
import { CoinSmall } from "../../assets";
import { HiXMark } from "react-icons/hi2";

const ComingSoon = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div className="modal-overlay scale-up-tr">
      <div className="coming-soon">
        <button className="exit" onClick={onClose}>
          <HiXMark size={32} fontWeight={700} color="#474EFF" />
        </button>
        <h1>List Airdrop</h1>
        <p>Coming Soon...</p>
        <img src={CoinSmall} alt="coin" className="coin" />
      </div>
    </div>
  );
};

export default ComingSoon;
