import React from "react";
import "./features.css";
import { IoIosArrowRoundForward } from "react-icons/io";

const FeaturesCard = ({ Heading, Text }) => {
  return (
    <div className="features-card">
      <div className="features-card__top">
        <h1>{Heading}</h1>
        <p>{Text}</p>
      </div>
      <div className="features-card__bottom">
        <a href="#start">Start Tapping</a>
        <IoIosArrowRoundForward color="#fff" size={48}/>
      </div>
    </div>
  );
};

const Features = () => {
  return (
    <div className="features">
      {/* <FeaturesCard
        Heading={"All-in-One Platform"}
        Text={
          "UniTap consolidates trending legit airdrops and meme coins verification in one app, simplifying the user experience and reducing the need between multiple platforms."
        }
      />
      <FeaturesCard
        Heading={"Transparent & Legitimate"}
        Text={
          "UniTap promotes transparency by verifying platform airdrops and showcasing only legitimate airdrops and providing users with peace of mind and protecting them from potential harm."
        }
      /> */}
      <FeaturesCard
        Heading={"Efficient & Convenient"}
        Text={
          "With UniTap’s tap-to-earn mechanism, users can effortlessly participate in airdrops, saving time and resources for builders and players while maximizing their earnings."
        }
      />
      <FeaturesCard
        Heading={"$UNIT Utility Token"}
        Text={
          "The UniTap ecosystem is powered by the $UNIT utility token which enables access to exclusive features, rewards and incentives, offering users a versatile and rewarding experience."
        }
      />
    </div>
  );
};

export default Features;
