import React, { useState } from "react";
import "./form.css";
import FileUploadButton from "./FileUploadButton";

const initialFormData = {
  projectName: "",
  website: "",
  businessEmail: "",
  locationAddress: "",
  phoneNumber: "",
  sector: "",
  description: "",
  startDateTime: "",
  endDateTime: "",
  landscapeImage: null,
  portraitImage: null,
  logoIcon: null,
  coinImage: null,
};
const Form = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [currentStep, setCurrentStep] = useState(0);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const showFileChange = (file) => {
    console.log("File selected:", file.name);
  };

  const validateStep = (step) => {
    const newErrors = {};
    if (step === 0) {
      if (!formData.projectName)
        newErrors.projectName = "Project name is required";
      if (!formData.website) newErrors.website = "Website is required";
      if (!formData.businessEmail)
        newErrors.businessEmail = "Business email is required";
      if (!formData.locationAddress)
        newErrors.locationAddress = "Location address is required";
      if (!formData.phoneNumber)
        newErrors.phoneNumber = "Phone number is required";
      if (!formData.sector) newErrors.sector = "Sector is required";
      if (!formData.description)
        newErrors.description = "Description is required";
      if (!formData.startDate) newErrors.startDate = "Start date is required";
      if (!formData.startTime) newErrors.startTime = "Start time is required";
      if (!formData.endDate) newErrors.endDate = "End date is required";
      if (!formData.endTime) newErrors.endTime = "End time is required";
    } else if (step === 1) {
      if (!formData.tapField1) newErrors.tapField1 = "Tap field 1 is required";
      // Add more validations as needed
    } else if (step === 2) {
      if (!formData.taskField1)
        newErrors.taskField1 = "Task field 1 is required";
      // Add more validations as needed
    }
    // Add more validation for other steps if necessary
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep(currentStep)) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  
  return (
    <div className="form">
      <div className="form-header">
        <h1>List Airdrop</h1>
        <p>List your airdrop in Universal Tap and access 10M+ users</p>
      </div>
      <div className="form-body">
        <div className="form-body__tab">
          <button
            disabled={currentStep === 0}
            onClick={() => setCurrentStep(0)}
          >
            About
          </button>
          <button
            disabled={currentStep === 1}
            onClick={() => setCurrentStep(1)}
          >
            Tap
          </button>
          <button
            disabled={currentStep === 2}
            onClick={() => setCurrentStep(2)}
          >
            Tasks
          </button>
          <button
            disabled={currentStep === 3}
            onClick={() => setCurrentStep(3)}
          >
            Boost
          </button>
          <button
            disabled={currentStep === 4}
            onClick={() => setCurrentStep(4)}
          >
            Owners
          </button>
        </div>
        <form action="">
          {currentStep === 0 && (
            <div className="about-step step">
              <div className="about-step-first">
                <input
                  type="text"
                  name="projectName"
                  value={formData.projectName}
                  onChange={handleInputChange}
                  placeholder="Project Name"
                />
                {errors.projectName && <span>{errors.projectName}</span>}
                <input
                  type="text"
                  name="website"
                  value={formData.website}
                  onChange={handleInputChange}
                  placeholder="Website"
                />
                {errors.website && <span>{errors.website}</span>}
                <input
                  type="email"
                  name="businessEmail"
                  value={formData.businessEmail}
                  onChange={handleInputChange}
                  placeholder="Business Email"
                />
                {errors.businessEmail && <span>{errors.businessEmail}</span>}
                <input
                  type="text"
                  name="locationAddress"
                  value={formData.locationAddress}
                  onChange={handleInputChange}
                  placeholder="Location Address"
                />
                {errors.locationAddress && (
                  <span>{errors.locationAddress}</span>
                )}
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  placeholder="Phone Number"
                />
                {errors.phoneNumber && <span>{errors.phoneNumber}</span>}
                <input
                  type="text"
                  name="sector"
                  value={formData.sector}
                  onChange={handleInputChange}
                  placeholder="Sector"
                />
                {errors.sector && <span>{errors.sector}</span>}
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Description"
                ></textarea>
                {errors.description && <span>{errors.description}</span>}
              </div>
              <div className="about-step-second">
                <h2>Airdrop Information</h2>
                <form action="">
                  <div className="about-step-second__dates">
                    <div className="about-step-second__dates_start-date">
                      <label>Start Date/Time</label>
                      <div className="about-step-second__dates_start-date-g-time">
                        <input
                          type="date"
                          name="startDate"
                          value={formData.startDate}
                          onChange={handleInputChange}
                        />
                        <input
                          type="time"
                          name="startTime"
                          value={formData.startTime}
                          onChange={handleInputChange}
                        />
                      </div>
                      {errors.startDateTime && (
                        <span>{errors.startDateTime}</span>
                      )}
                    </div>
                    <div className="about-step-second__dates_end-date">
                      <label>End Date/Time</label>
                      <div className="about-step-second__dates_end-date-g-time">
                        <input
                          type="date"
                          name="endDate"
                          value={formData.endDate}
                          onChange={handleInputChange}
                        />
                        <input
                          type="time"
                          name="endTime"
                          value={formData.endTime}
                          onChange={handleInputChange}
                        />
                      </div>
                      {errors.endDateTime && <span>{errors.endDateTime}</span>}
                    </div>
                  </div>
                  <div className="about-step-second__landscape">
                    <label>
                      Landscape Cover Image (format: JPEG only, size: 590x224,
                      max: 5MB)
                    </label>
                    <FileUploadButton onFileChange={showFileChange} />
                  </div>
                  <div className="about-step-second__portrait">
                    <label>
                      Portrait Cover Image (format: JPEG only, size: 247x224,
                      max: 5MB)
                    </label>
                    <FileUploadButton onFileChange={showFileChange} />
                  </div>
                  <div className="about-step-second__logo">
                    <label>
                      Logo Icon (format: PNG only, size: 400x400, max: 2MB)
                    </label>
                    <FileUploadButton onFileChange={showFileChange} />
                  </div>
                  <div className="about-step-second__3D">
                    <label>
                      Logo Icon (format: PNG only, size: 400x400, max: 2MB)
                    </label>
                    <FileUploadButton onFileChange={showFileChange} />
                  </div>
                </form>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Form;
